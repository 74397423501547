import { throwError as observableThrowError, Observable, BehaviorSubject, from } from 'rxjs';
import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";
import { ApiService } from './app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material';
import { FixedTableComponent } from './tables/fixed-table/fixed-table.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from "@angular/router";
import { environment } from '../environments/environment';



@Injectable({
	providedIn: 'root'
})

export class InterceptService implements HttpInterceptor {
	count: number;
	constructor(private _authService: ApiService, private _spinner: NgxSpinnerService, private _snackbar: MatSnackBar, private _router: Router) {
		this.count = 0;
	}

	// intercept request and add token
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

		let accessToken = sessionStorage.getItem("access_token");

		if (req.url.indexOf("/fcm/send") > 0 && req.method == "POST") {
			return from(accessToken).pipe(
				switchMap(res => {
					if (res)
						req = req.clone({ setHeaders: { 'Authorization': `key=${environment.firebaseFCMServerKey}`, 'Content-Type': 'application/json' } });
					return next.handle(req);
				})
			)
		}

		return next.handle(req).pipe(
			catchError(err => {
				console.log("error in interceptor", err);
				if (err instanceof HttpErrorResponse) {
					if (err.error.error == "invalid_token" || err.status == 401) {
						this.getRefreshToken();
						// this._snackbar.open('Your session expired please login thank you!', 'X', {duration: 3000});
						// localStorage.clear();
						// sessionStorage.clear();
						// this._router.navigate(['/login']);
						// this.count++;
					} else if (err.status == 0) {
						this._snackbar.open('Please Check Internet Connection Thank You !', 'X');
					}
					return observableThrowError(err);
				}
			})
		);
	};

	getRefreshToken() {
		this._authService.postRefreshToken().subscribe(info => {
			let infoDetails = JSON.parse(info._body);
			if (infoDetails.access_token) {
				let accesstok = infoDetails.access_token;
				let refreshtok = infoDetails.refresh_token;
				// sessionStorage.removeItem('access_token');
				// sessionStorage.removeItem('refresh_token');
				sessionStorage.setItem('access_token', accesstok);
				sessionStorage.setItem('refresh_token', refreshtok);
			}
		}, err => {
			let errDetails = JSON.parse(err._body);
			// this._authService.deleteToken(sessison.userid)
			if (errDetails.error == "invalid_token") {
				// this.openRedirectDialog();
				this._snackbar.open('Your session expired please login thank you!', 'X', { duration: 3000 });
				localStorage.clear();
				sessionStorage.clear();
				this._router.navigate(['/login']);
			}
		});
	}


	// openRedirectDialog() {
	//  if(localStorage.count == 0 || localStorage.count == "0"){
	//    const dialogRef = this._dialog.open(FixedTableComponent, {
	//      width: '600px',
	//      disableClose: true,
	//    });
	//  }

	// const dialogRef = this._dialog.open(FixedTableComponent, {
	//  width: '500px',
	//  disableClose: true,
	// });
	// dialogRef.afterClosed().subscribe(result => {

	// });
}

// }
